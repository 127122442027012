<div class="footer-route-background">
    <img class="logo" src="assets/images/finstein.svg" alt="">
    <section class="internal-page">
        <section class="internal-page-section">
            <h1 class="page-title" translate>privacy.title</h1>
            <article>
                <p class="mb-4" translate>privacy.section-1.paragraph-1</p>
                <p translate>privacy.section-1.paragraph-2</p>

                <span class="internal-section-title mt-6" translate>privacy.section-2.title</span>
                <p class="mb-5" [innerHTML]="'privacy.section-2.paragraph-1' | translate"></p>
                <p class="text-center no-margin" translate>privacy.section-2.paragraph-2</p>
                <p class="text-center no-margin" translate>privacy.section-2.paragraph-3</p>
                <p class="text-center no-margin" translate>privacy.section-2.paragraph-4</p>
                <p class="text-center no-margin" translate>privacy.section-2.paragraph-5</p>


                <span class=    "internal-section-title mt-6" translate>privacy.section-3.title</span>
                <p class="no-margin" [innerHTML]="'privacy.section-3.paragraph-1' | translate"></p>
                <p class="no-margin" [innerHTML]="'privacy.section-3.paragraph-2' | translate"></p>
                <p class="no-margin" [innerHTML]="'privacy.section-3.paragraph-3' | translate"></p>


                <span class="internal-section-title mt-6" translate>privacy.section-4.title</span>
                <p class="mb-4" translate>privacy.section-4.paragraph-1</p>
                <p translate>privacy.section-4.paragraph-2</p>


                <span class="internal-section-title mt-6" translate>privacy.section-5.title</span>
                <p class="mb-4" translate>privacy.section-5.paragraph-1</p>
                <p translate>privacy.section-5.paragraph-2</p>


                <span class="internal-section-title mt-6" translate>privacy.section-6.title</span>
                <p class="mb-4" translate>privacy.section-6.paragraph-1</p>
                <p translate>privacy.section-6.paragraph-2</p>


                <span class="internal-section-title mt-6" translate>privacy.section-7.title</span>
                <p class="mb-6" translate>privacy.section-7.paragraph-1</p>
                <ul class="list">
                    <li class="mb-3" translate>privacy.section-7.list.list-item-1</li>
                    <li class="mb-3" translate>privacy.section-7.list.list-item-2</li>
                    <li class="mb-3" translate>privacy.section-7.list.list-item-3</li>
                    <li class="mb-3" translate>privacy.section-7.list.list-item-4</li>
                    <li class="mb-3" translate>privacy.section-7.list.list-item-5</li>
                    <li class="mb-3" translate>privacy.section-7.list.list-item-6</li>
                    <li class="mb-3" translate>privacy.section-7.list.list-item-7</li>
                    <li translate>privacy.section-7.list.list-item-8</li>
                </ul>


                <span class="internal-section-title mt-6" translate>privacy.section-8.title</span>
                <p translate>privacy.section-8.paragraph-1</p>


                <span class="internal-section-title mt-6" translate>privacy.section-9.title</span>
                <span class="internal-section-title-bold text-uppercase mt-6 mb-4" translate>privacy.section-9.subtitle-1</span>
                <p class="mb-4" translate>privacy.section-9.paragraph-1</p>

                <ul class="list">
                    <li translate>privacy.section-9.list.list-item-1</li>
                    <li translate>privacy.section-9.list.list-item-2</li>
                    <li translate>privacy.section-9.list.list-item-3</li>
                    <li translate>privacy.section-9.list.list-item-4</li>
                    <li translate>privacy.section-9.list.list-item-5</li>
                </ul>

                <p class="mt-4 mb-4" translate>privacy.section-9.paragraph-2</p>

                <span class="internal-section-title-bold text-uppercase mt-6 mb-4" translate>privacy.section-9.subtitle-2</span>
                <p class="mb-4" translate>privacy.section-9.paragraph-3</p>

                <span class="internal-section-title-bold text-uppercase mt-6 mb-4" translate>privacy.section-9.subtitle-3</span>
                <p class="mb-4" translate>privacy.section-9.paragraph-4</p>


                <span class="internal-section-title mt-6" translate>privacy.section-10.title</span>
                <span class="internal-section-title-bold text-uppercase mt-6 mb-4" translate>privacy.section-10.subtitle-1</span>
                <p class="mb-4" translate>privacy.section-10.paragraph-1</p>
                <p class="mb-4" translate>privacy.section-10.paragraph-2</p>

                <span class="internal-section-title-bold text-uppercase mt-6 mb-4" translate>privacy.section-10.subtitle-2</span>
                <p class="mb-4" translate>privacy.section-10.paragraph-3</p>

                <span class="internal-section-title-bold text-uppercase mt-6 mb-4" translate>privacy.section-10.subtitle-3</span>
                <p class="mb-4" translate>privacy.section-10.paragraph-4</p>
                <p class="mb-4" translate>privacy.section-10.paragraph-5</p>


                <span class="internal-section-title mt-6" translate>privacy.section-11.title</span>
                <span class="internal-section-title-bold text-uppercase mt-6 mb-4" translate>privacy.section-11.subtitle-1</span>
                <p class="mb-4" translate>privacy.section-11.paragraph-1</p>
                <p class="mb-4" translate>privacy.section-11.paragraph-2</p>

                <span class="internal-section-title-bold text-uppercase mt-6 mb-4" translate>privacy.section-11.subtitle-2</span>
                <p class="mb-4" translate>privacy.section-11.paragraph-3</p>

                <span class="internal-section-title-bold text-uppercase mt-6 mb-4" translate>privacy.section-11.subtitle-3</span>
                <p class="mb-4" translate>privacy.section-11.paragraph-4</p>


                <span class="internal-section-title mt-6" translate>privacy.section-12.title</span>
                <span class="internal-section-title-bold text-uppercase mt-6 mb-4" translate>privacy.section-12.subtitle-1</span>
                <p class="mb-4" translate>privacy.section-12.paragraph-1</p>
                <p class="mb-4" translate>privacy.section-12.paragraph-2</p>

                <span class="internal-section-title-bold text-uppercase mt-6 mb-4" translate>privacy.section-12.subtitle-2</span>
                <p class="mb-4" translate>privacy.section-12.paragraph-3</p>

                <span class="internal-section-title-bold text-uppercase mt-6 mb-4" translate>privacy.section-12.subtitle-3</span>
                <p class="mb-4" translate>privacy.section-12.paragraph-4</p>


                <span class="internal-section-title mt-6" translate>privacy.section-13.title</span>
                <span class="internal-section-title-bold text-uppercase mt-6 mb-4" translate>privacy.section-13.subtitle-1</span>
                <p class="mb-4" translate>privacy.section-13.paragraph-1</p>
                <p class="mb-4" translate>privacy.section-13.paragraph-2</p>
                <p class="mb-4" translate>privacy.section-13.paragraph-3</p>

                <span class="internal-section-title-bold text-uppercase mt-6 mb-4" translate>privacy.section-13.subtitle-2</span>
                <p class="mb-4" translate>privacy.section-13.paragraph-4</p>

                <span class="internal-section-title-bold text-uppercase mt-6 mb-4" translate>privacy.section-13.subtitle-3</span>
                <p class="mb-4" translate>privacy.section-13.paragraph-5</p>


                <span class="internal-section-title mt-6" translate>privacy.section-14.title</span>
                <span class="internal-section-title-bold text-uppercase mt-6 mb-4" translate>privacy.section-14.subtitle-1</span>
                <p class="mb-4" translate>privacy.section-14.paragraph-1</p>

                <span class="internal-section-title-bold text-uppercase mt-6 mb-4" translate>privacy.section-14.subtitle-2</span>
                <p class="mb-4" translate>privacy.section-14.paragraph-2</p>

                <span class="internal-section-title-bold text-uppercase mt-6 mb-4" translate>privacy.section-14.subtitle-3</span>
                <p class="mb-4" translate>privacy.section-14.paragraph-3</p>
                <p class="mb-4" translate>privacy.section-14.paragraph-4</p>
            </article>
        </section>
    </section>
</div>
