import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { concatMap, take } from 'rxjs/operators';

@Component({
    selector: 'app-privacy',
    templateUrl: './privacy.component.html',
    styleUrls: [
        './privacy.component.scss',
        '../imprint/imprint.component.scss',
    ],
})
export class PrivacyComponent implements OnInit {
    view: string;

    constructor(
        private route: ActivatedRoute,
        private translate: TranslateService,
        protected router: Router,
        public el: ElementRef,
        public renderer: Renderer2
    ) {}

    ngOnInit() {
        this.route.queryParamMap
            .pipe(
                concatMap((map: ParamMap) => {
                    this.view = map.get('view');
                    const lang = map.get('lang');
                    if (lang) {
                        return this.translate.use(lang);
                    }
                    return of();
                }),
                take(1)
            )
            .subscribe();
    }
}
