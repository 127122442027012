import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PrivacyComponent} from './privacy.component';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule, Routes} from '@angular/router';

const routes: Routes = [
    {
        path: '',
        data: {},
        component: PrivacyComponent,
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        TranslateModule
    ],
    declarations: [
        PrivacyComponent
    ]
})
export class PrivacyModule { }
